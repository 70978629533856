import { Controller } from 'stimulus'
import { Collapse } from 'bootstrap'
export default class extends Controller {
  static targets = ['list', 'button']
  static values = { closedText: String, openText: String }

  collapsed = false

  connect () {
    this.setButtonText()
  }

  toggle (e) {
    e.preventDefault()

    this.items.forEach(element => {
      const instance = Collapse.getOrCreateInstance(element)

      if (this.collapsed) instance.show()
      else instance.hide()
    })
    this.setButtonText()

    this.collapsed = !this.collapsed
  }

  get items () {
    return this.listTarget.querySelectorAll('.collapse')
  }

  setButtonText () {
    if (!this.hasButtonTarget)
      return

    if (this.collapsed)
      this.buttonTarget.textContent = this.openTextValue
    else
      this.buttonTarget.textContent = this.closedTextValue
  }
}
