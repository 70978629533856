// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'
import { Modal } from 'bootstrap'
export default class extends Controller {
  static targets = ['button', 'input', 'modal']
  static values = {
    modal: String,
  }

  connect () {
    this.modal = new Modal(document.getElementById(this.modalValue))
  }

  showModal () {
    this.modal.show()
    this.modal._element.addEventListener('pagetree:select_node', (e) => {
      console.log(e.detail)
      this.inputTarget.value = e.detail.name
      this.modal.hide()
      console.log('HEJ')
    }, { once: true, capture: true })
  }
}
