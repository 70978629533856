import { Controller } from 'stimulus'
import FullEditor from 'ckeditor5-build-full'
export default class extends Controller {
  editor = null;
  connect () {
    if (this.editor == null) {
      this.editor = FullEditor
        .create(this.element, {
          toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'alignment'],
          heading: {
            options: [
              { model: 'paragraph', title: 'Brödtext' },
              { model: 'heading3', view: 'h3', title: 'Rubrik 1', class: 'ck-heading_heading2' },
              { model: 'heading4', view: 'h4', title: 'Rubrik 2', class: 'ck-heading_heading3' },
              { model: 'heading5', view: 'h5', title: 'Rubrik 3', class: 'ck-heading_heading4' },
            ],
          },
          mediaEmbed: {
            previewsInData: true,
          },
          // Swedish quotes
          typing: {
            transformations: {
              include: ['typography', 'symbols', 'mathematical'],
              extra: [
                { from: buildQuotesRegExp('"'), to: [null, '”', null, '”'] },
                { from: buildQuotesRegExp("'"), to: [null, '’', null, '’'] },
              ],
            },
          },
        })
        .then(editor => {

        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}

// https://github.com/ckeditor/ckeditor5/blob/d1a6431b76b535379b5990c952a15f63441655ad/packages/ckeditor5-typing/src/texttransformation.ts#L239C1-L241C2
function buildQuotesRegExp (quoteCharacter) {
  return new RegExp(`(^|\\s)(${quoteCharacter})([^${quoteCharacter}]*)(${quoteCharacter})$`)
}
