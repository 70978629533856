import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'
export default class extends Controller {
  static values = { pickTime: Boolean, allowInput: Boolean, dateFormat: String }
  connect () {
    flatpickr(this.element, {
      enableTime: this.hasPickTimeValue ? this.pickTimeValue : true,
      dateFormat: this.hasDateFormatValue ? this.dateFormatValue : 'Y-m-d H:i',
      time_24hr: true,
      allowInput: this.hasAllowInputValue ? this.allowInputValue : true,
    })
  }
}
