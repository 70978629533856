import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  static values = { url: String, placeholderText: String }

  connect () {
    const _this = this
    let slim = null
    this.element.classList.remove('form-control')
    if (this.hasUrlValue) {
      slim = new SlimSelect({
        select: this.element,
        searchingText: 'Söker...',
        // placeholder: this.hasPlaceholderTextValue ? this.placeholderTextValue : 'Välj',
        ajax: function (search, callback) {
          if (search.length < 2) {
            // eslint-disable-next-line node/no-callback-literal
            callback('Skriv minst 2 tecken...')
            return
          }

          const url = new URL(_this.urlValue)
          url.search = new URLSearchParams({ query: search })

          fetch(url)
            .then((response) => {
              return response.json()
            })
            .then((json) => {
              console.log(json)
              callback(json)
            })
            .catch(error => callback(error))
        },

      })
    } else {
      slim = new SlimSelect({
        select: this.element,
        searchingText: 'Söker...',
        placeholder: this.placeholderTextValue,

      })
    }
  }
}
