import { Controller } from 'stimulus'

export default class extends Controller {
  url = null
  connect () {
    this.url = location.href.replace(/\/$/, '')
    this.activateTabFromUrl()
  }

  activateTabFromUrl () {
    if (location.hash) {
      const hash = this.url.split('#')
      const currentTab = this.element.querySelector('[data-bs-target="#' + hash[1] + '"]')
      currentTab?.click()
    }
  }

  pushToUrl (e) {
    const hash = e.target.getAttribute('data-bs-target')
    if (!hash) return

    const newUrl = this.url.split('#')[0] + hash
    history.replaceState(null, null, newUrl)
  }
}
