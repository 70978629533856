import { Controller } from 'stimulus'
import { gql, GraphQLClient } from 'graphql-request'
import { Modal as BootstrapModal } from 'bootstrap'

const query = gql`
  query ($id: ID!) {
    nodeById(id: $id) {
      allowedContentTypes
    }
  }
`

export default class extends Controller {
  static targets = ['modal']
  static values = { workspaceToken: String }

  graphqlClient = null;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  connect () {
    this.graphqlClient = new GraphQLClient(
      '/graphql',
      {
        headers: {
          'X-Workspace-Token': this.workspaceTokenValue,
        },
      },
    )

    this.modal = new BootstrapModal(this.modalTarget, {})
    // this.modalTarget.
    document.addEventListener('pagetree:select_node', (e) => {
      this.changeParent(e.detail)
      this.modal.hide()
    })
  }

  chooseParent () {
    this.modal.show()
  }

  // changeParent (data) {
  //   const act = this.getAllowedContentTypesForNode(data.selectedId)
  // }

  async getAllowedContentTypesForNode (nodeId) {
    const variables = {
      id: nodeId,
    }

    const data = await this.graphqlClient.request(query, variables)
    // return await response.data.nodeById?.allowedContentTypes
    return data.nodeById.allowedContentTypes // .nodeById.allowedContentTypes
  }
}
