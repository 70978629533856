// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import StimulusSlimSelect from 'stimulus-slimselect'
import NestedForm from './nested_form_controller'
import TextareaAutogrow from 'stimulus-textarea-autogrow'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))
application.register('slimselect', StimulusSlimSelect)
application.register('nested-form', NestedForm)
application.register('textarea-autogrow', TextareaAutogrow)

export default application
