import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    /** @type {HTMLElement} */
    const element = this.element

    // Add intersection observer to this.element
    this.observer = new IntersectionObserver(() => {
      element.src = element.dataset.src
      delete element.dataset.src

      this.observer.unobserve(element)
    }, {
      threshold: 0.01,
    })

    this.observer.observe(element)
  }
}
