import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['fallback', 'error']

  /** @type {HTMLIFrameElement} */
  iframe
  connected = false

  connect() {
    this.iframe = this.element.querySelector('iframe')
    this.iframe.style.height = '0'

    if (this.hasErrorTarget)
      this.errorTarget.style.setProperty('display', 'none', 'important')

    this.iframe.onload = () => setTimeout(() => {
      if (this.connected)
        return

      if (this.hasFallbackTarget)
        this.fallbackTarget.style.setProperty('display', 'none', 'important')

      if (this.hasErrorTarget)
        this.errorTarget.style.removeProperty('display')
    }, 3000)

    window.addEventListener('message', this.onEvent.bind(this))
  }

  onEvent(/** @type {MessageEvent} */ message) {
    if (message.source !== this.iframe.contentWindow)
      return

    if (message.data.type !== 'cms:block-state')
      return

    this.connected = true
    const height = message.data.height
    this.iframe.style.height = `${height}px`

    if (this.hasFallbackTarget)
      this.fallbackTarget.style.setProperty('display', 'none', 'important')

    if (this.hasErrorTarget)
      this.errorTarget.style.setProperty('display', 'none', 'important')
  }
}
