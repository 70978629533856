import { Controller } from 'stimulus'

export default class extends Controller {
  async connect () {
    const datalist = document.createElement('datalist')
    const hash = (Math.random() + 1).toString(36).substring(7)
    const datalistId = `datalist-${hash}`

    datalist.id = datalistId
    this.element.parentElement.appendChild(datalist)
    this.element.setAttribute('list', datalistId)

    // Populate datalist asyncronously
    this.fetchList().then(list => {
      list.forEach(item => {
        const option = document.createElement('option')
        option.value = item.value || item.id
        option.innerText = item.text || item.label || item.name || item.title
        datalist.appendChild(option)
      })
    })
  }

  get path () {
    return this.element.getAttribute('data-datalist-path')
  }

  async fetchList () {
    return fetch(this.path)
      .then(response => response.json())
      .catch((error) => {
        console.error(error)
        return []
      })
  }
}
