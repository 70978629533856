/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '@fortawesome/fontawesome-free/js/all'
import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import Rails from '@rails/ujs'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../../components'
import '../channels'
import '../controllers'
import '../stylesheets/main.scss'

Rails.start()
ActiveStorage.start()

document.addEventListener('turbo:before-stream-render', (event) => {
  // Add a class to an element we are about to add to the page
  // as defined by its "data-stream-enter-class"
  if (event.target.firstElementChild instanceof HTMLTemplateElement) {
    const enterAnimationClass = event.target.templateContent.firstElementChild.dataset.streamEnterClass
    if (enterAnimationClass)
      event.target.templateContent.firstElementChild.classList.add(enterAnimationClass)
  }

  // Add a class to an element we are about to remove from the pagec
  // as defined by its "data-stream-exit-class"
  const elementToRemove = document.getElementById(event.target.target)
  const streamExitClass = elementToRemove?.dataset.streamExitClass
  if (streamExitClass) {
    // Intercept the removal of the element
    event.preventDefault()
    elementToRemove.classList.add(streamExitClass)
    // Wait for its animation to end before removing the element
    elementToRemove.addEventListener('animationend', function () {
      event.target.performAction()
    })
  }
})
