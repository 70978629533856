import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['field1', 'field2', 'field3']

  connect () {
    this.field1Target.addEventListener('input', this.updatedField1.bind(this))
    this.field2Target.addEventListener('input', this.updatedField2.bind(this))
    this.field3Target.addEventListener('input', this.updatedField2.bind(this))
  }

  updatedField1 (e) {
    if (e.target.value !== '') {
      this.field2Target.disabled = true
      this.field3Target.disabled = true
      this.field2Target.value = ''
      this.field3Target.value = ''
    } else {
      this.field2Target.disabled = false
      this.field3Target.disabled = false
    }
  }

  updatedField2 (e) {
    if (e.target.value !== '') {
      this.field1Target.disabled = true
      this.field1Target.value = ''
    } else if (this.field3Target.value === '' && this.field2Target.value === '') {
      this.field1Target.disabled = false
    }
  }
}
