import Rails from '@rails/ujs'
import Sortable from 'sortablejs'
import { Controller } from 'stimulus'
export default class extends Controller {
  connect () {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
      handle: '.handle',
      ghostClass: 'bg-light',
      filter: '.non-sortable',
      direction: 'vertical',
      draggable: '.draggable-item',
    })
  }

  end (event) {
    const id = event.item.dataset.id
    const data = new FormData()
    const newPosition = event.newIndex + 1
    data.append('position', newPosition)
    Rails.ajax({
      url: this.data.get('url').replace(':id', id),
      type: 'PATCH',
      data: data,
      success () {
      },
    })
  }
}
