import { Controller } from 'stimulus'

export default class extends Controller {
  sync = false

  connect () {
    if (!this.startDateInput || !this.endDateInput)
      return console.error('AutosetEndDateController: Could not find start or end date input')

    // Set initial sync state
    this.refreshSync()

    // Enable sync
    this.startDateInput.addEventListener('input', this.syncDates.bind(this))

    // Possibly disable sync if end date is changed
    this.endDateInput.addEventListener('input', this.refreshSync.bind(this))
  }

  disconnect () {
    this.startDateInput.removeEventListener('input', this.syncDates.bind(this))
    this.endDateInput.removeEventListener('input', this.refreshSync.bind(this))
  }

  refreshSync () {
    this.sync = this.startDateInput.value === this.endDateInput.value
  }

  syncDates () {
    // Sync dates if sync is enabled
    if (this.sync)
      this.endDateInput.value = this.startDateInput.value

    // Re-enable sync if dates are equal
    this.refreshSync()
  }

  get startDateInput () {
    return this.element.querySelector('input[name*="start_date"]')
  }

  get endDateInput () {
    return this.element.querySelector('input[name*="end_date"]')
  }
}
