import { Controller } from 'stimulus'

// Hotfixed version of stimulus-rails-nested-form to support multi-nested forms
// To make it work, you MUST set a custom data-nested-form-new-record-value="NEW_RECORD_X" on the nested (inner) form
// https://github.com/stimulus-components/stimulus-rails-nested-form/blob/02dbc411e727d36aa1959f2375426f93de128434/src/index.ts
export default class extends Controller {
  // targetTarget: string
  // templateTarget: string
  // wrapperSelectorValue: string
  // wrapperSelector: string
  // newRecordValue: string

  static targets = ['target', 'template']
  static values = {
    wrapperSelector: String,
    newRecord: String,
  }

  initialize () {
    this.wrapperSelector = this.wrapperSelectorValue || '.nested-form-wrapper'

    // Warn user for bad usage
    if (this.templateTarget.content.querySelector('[data-controller="nested-form"]:not([data-nested-form-new-record-value])'))
      console.error('It looks like you are nesting multiple forms using the nested-form stimulus controller. Please add data-nested-form-new-record-value="NEW_RECORD_X" to your inner form or you will have a bad day.')
  }

  add (e) {
    e.preventDefault()

    // Hotfix
    const newRecordPattern = this.newRecordValue || 'NEW_RECORD'
    const pattern = new RegExp(newRecordPattern, 'g')

    const content = this.templateTarget.innerHTML.replace(pattern, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove (e) {
    e.preventDefault()

    /** @type {HTMLElement} */
    const wrapper = e.target.closest(this.wrapperSelector)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      /** @type {HTMLInputElement} */
      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = '1'
    }
  }
}
