import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button']
  submitListener = null;
  turboEndListener = null;
  connect () {
    this.submitListener = this.element.addEventListener('submit', this.disableButton.bind(this))
    this.turboEndListener = this.element.addEventListener('turbo:submit-end', this.enableButton.bind(this))
  }

  disconnect () {
    this.element.removeEventListener('submit', this.submitListener)
    this.element.removeEventListener('turbo:submit-end', this.turboEndListener)
  }

  disableButton (e) {
    this.buttonTarget.disabled = true
  }

  enableButton (e) {
    this.buttonTarget.disabled = false
  }
}
