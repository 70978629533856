import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['form', 'button']
  connect () {

  }

  submit (e) {
    e.preventDefault()
    if (this.hasFormTarget) {
      const c = confirm('Det finns osparade block. Spara blocken också?')
      if (c == true) {
        this.formTargets.forEach((form) => {
          form.click()
        })
        Rails.fire(this.element, 'submit')
      }
    } else {
      Rails.fire(this.element, 'submit')
    }
  }
}
